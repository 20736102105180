import { useContext, useEffect, useMemo, useState } from "react";
import { NextPage } from "next";

// Components
import { Header, Footer, PageContext } from "mlp-sdk";

// Providers
import { PageProvider } from "providers/PageProvider";

// Utils
import { prepareNavData } from "mlp-sdk/utils/common";
import { trackEvent } from "mlp-sdk/utils/firebase";

// Hooks
import Head from "next/head";
import NotFound from "@components/NotFound/NotFound";
import navData from "@constants/navData.json";
import pageData from "@constants/pageData.json";

const Home = () => {
  const { page } = useContext(PageContext);
  const [originURL, setOriginURL] = useState<string>("");
  const { navData, networks } = useMemo(
    () => prepareNavData(page.data.navData, undefined),
    [page.data.navData]
  );
  const pageData = page.data.pageData;
  const bannerData = pageData.banner;
  useEffect(() => {
    const href = window.location.href;
    setOriginURL(href);
  }, []);

  useEffect(() => {
    const navItems = pageData.sections.map(
      (section: any) =>
        section?.section_details?.section_id ||
        section?.sectionDetails?.section_id
    );

    const mainElements = navItems.map((id: string) =>
      document.getElementById(id)
    );

    const observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            trackEvent(`VIEW_${entry.target.id}`);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    mainElements.forEach(
      (element: Element) => element && observer.observe(element)
    );

    // cleanup function
    return () => {
      mainElements.forEach(
        (element: Element) => element && observer.unobserve(element)
      );
    };
  }, [pageData.sections]);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href={originURL} />
      </Head>
      <Header
        data={navData}
        networks={networks}
        banner={bannerData}
        tvl={undefined}
      />
      <NotFound />
      <Footer data={navData} isEmailModalRequired={false} />
    </>
  );
};

const HomePage: NextPage = () => {
  return (
    <PageProvider>
      <Home />
    </PageProvider>
  );
};

export async function getStaticProps() {
  return {
    props: {
      page: {
        data: {
          navData,
          pageData,
        },
        error: { navError: null, pageError: null },
      },
    },
  };
}

export default HomePage;
